import React, { useState } from "react";
import InputField from '../../../components/InputField'
import Button from "../../../components/Button";

const Form = ({ onClose }) => {
  const [email, setEmail] = useState("");

  // Button handler
  const handleSendLink = () => {
    onClose();
  };

  return (
    <div className="forgotPassword-container">
      <InputField
        type={"text"}
        name={"email"}
        label={"Enter Email address"}
        value={email}
        setValue={(e) => setEmail(e.target.value)}
      />

      <Button
        text={"Reset Password"}
        handler={handleSendLink}
        width={"full"}
        rounded={"medium"}
      />
    </div>
  );
};

export default Form;
