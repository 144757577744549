import React from "react";
import Form from "./Form";

const ForgotPasswordModel = ({ isOpen, onClose }) => {
  return isOpen ? (
    <div className="contact-modal-container">
      <div className="forgotPassword-overlay" onClick={onClose}></div>
      <div className="contact-modal-content">
        <div className="container mx-auto" id="forgotPassword-box">
          {/* Header */}
          <div className="contact-heading">
            <div className="heading">
              Forgot Password
            </div>
            <div onClick={onClose} className="model-close-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="forgotPassword-close-icon"
                style={{ width: "20", height: "20" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="forgotPassword-content">
            We’ll send a link to this email if it matches an existing CipherTEA account.
            Please follow the steps provided in the email to reset your password.
          </div>
          {/* Form Component Here */}
          <Form onClose={onClose} />
        </div>
      </div>
    </div>
  ) : null;
};

export default ForgotPasswordModel;
