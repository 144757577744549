import React, { useState, useEffect } from "react";
import "../../styles/Setting.css";
import {
  UserCard,
  CardLayout,
  EmailForm,
  CompanyForm,
  PasswordForm,
  ModalLayout,
  AddForm,
  DeleteForm,
  EmailVerifyForm,
} from "./index";
import AlertBox from "../../components/AlertBox";
import * as API from "../../controller/UserController";
import { USERS } from "../../staticData/Users";
import { useNavigate } from "react-router-dom";


const Settings = ({ user }) => {
  const navigate = useNavigate();

  // Card states
  const [activeCard, setActiveCard] = useState(false);
  const [activeCard2, setActiveCard2] = useState(false);
  const [activeCard3, setActiveCard3] = useState(false);
  const [activeCard4, setActiveCard4] = useState(false);

  // Modal states
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  // Alert box states
  const [alertMessage, setAlertMessage] = useState(""); // For the alert message
  const [alertType, setAlertType] = useState(""); // For the alert type (error/success)
  const [showAlert, setShowAlert] = useState(false); // To control visibility of alert box

  // Data states
  useEffect(() => {
    const fetchData = async () => {
      await getClientInfo(); // Ensure the async operation completes
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchLatestUserData = async () => {
      await getLatestUserInfo(); // Ensure the async operation completes
    };
    fetchLatestUserData();
  }, []);

  const [fakeUsers, setFakeUsers] = useState(USERS);
  const [client, setClient] = useState(sessionStorage.getItem("client") ? JSON.parse(sessionStorage.getItem("client")) : fakeUsers);
  const [userData, setUserData] = useState({
    //Initial Data set from the passed User and Client Object
    companyName: client.client_business_name || "",
  });

  const [form, setForm] = useState({
    email: "",
    reEnterEmail: "",
    company: "",
    reEnterCompany: "",
    oldPassword: "",
    newPassword: "",
    reEnterNewPassword: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getClientInfo = async () => {
    API.getClientById(user.userResponseList[0].user_company_id).then((response) => {
      if (response.status === "OK") {
        sessionStorage.setItem("client", JSON.stringify(response));
        setClient({ ...client, response });
      } else {
        console.error("CLIENT NOT SET:", response);
      }
    });
  };

  const getLatestUserInfo = async () => {
    const response = await API.getUserById(user.userResponseList[0].user_id);
    if (response.status === "OK") {
      setUserData((prev) => ({
        ...prev,
        ...response,
        companyName: client.client_business_name,
        user_email: user.userResponseList[0].user_email,
      }));
    } else {
      console.error(response);
    }
    console.log(user);
  };


  const handleEmailCardSubmit = () => {
    if (form.email === "" || form.reEnterEmail === "") {
      setAlertMessage("Please fill in all the fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    } if (form.email !== form.reEnterEmail) {
      setAlertMessage("Emails do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    } if (form.email === userData.user_email) {
      setAlertMessage("Please enter a new email address.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    else if (!emailVerified) {
      setAlertMessage("Please click 'Send Code' to verify your email first");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    const userpayload = {
      "user-email": form.email,
      "user-id": user.userResponseList[0].user_id,
    };
    API.updateConsumerData(userpayload).then((response) => {
      if (response?.status === "OK") {
        navigate("/settings")
        console.log(response.status);
        setAlertMessage("Email Changed Successfully.");
        setAlertType("success");
        setShowAlert(true);
        setUserData({ ...userData, user_email: form.email });
        setForm({ ...form, email: form.email, reEnterEmail: form.reEnterEmail });
        setEmailVerified(true);
      } else {
        console.error(response.status);
        setAlertMessage("Email could not be changed.");
        setAlertType("error");
        setShowAlert(true);
        setEmailVerified(false);
      }
    });

  };

  const handleCompanyCardSubmit = () => {
    if (form.company === "" || form.reEnterCompany === "") {
      setAlertMessage("Please fill in all the fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    } if (form.company !== form.reEnterCompany) {
      setAlertMessage("Please make sure the company names match.");
      setAlertType("error");
      setShowAlert(true);
      return;
    } if (form.company === userData.companyName) {
      setAlertMessage("Please enter a new company name.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    const clientPayload = {
      "client-id": client.client_id,
      "client-business-name": form.reEnterCompany
    };
    API.updateClientData(clientPayload).then((response) => {
      if (response?.status === "OK") {
        navigate("/settings")
        setAlertMessage("Company Name Changed Successfully");
        setAlertType("success");
        setShowAlert(true);
        setClient({ ...client, client_business_name: form.company });
        setForm({ ...form, company: form.company, reEnterCompany: form.reEnterCompany });
        return;
      } else {
        navigate("/settings")
        setAlertMessage("Company Name could not be changed.");
        setAlertType("error");
        setShowAlert(true);
        return;
      }
    });

  };

  const handlePasswordCardSubmit = () => {
    if (form.oldPassword === "" || form.newPassword === "" || form.reEnterNewPassword === "") {
      setAlertMessage("Please fill all the fields");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.newPassword !== form.reEnterNewPassword) {
      setAlertMessage("Passwords do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    if (form.oldPassword === form.newPassword) {
      setAlertMessage("New password cannot be the same as older password.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    API.verifyLogin(userData.user_email, form.oldPassword).then((response) => {
      if (response?.status === "BAD_REQUEST") {
        navigate("/settings")
        console.log(response.status);
        setAlertMessage("Please ensure the original password is correct.");
        setAlertType("error");
        setShowAlert(true);
        return;
      } else {
        const userpayload = {
          "user-id": user.userResponseList[0].user_id,
          "user-email": userData.user_email,
          "user-password": form.newPassword,
        };
        API.updateConsumerData(userpayload).then((response) => {
          if (response?.status === "OK") {
            navigate("/settings")
            setAlertMessage("Password Changed Successfully");
            setAlertType("success");
            setShowAlert(true);
            setUserData({ ...userData, companyName: form.company, email: form.email });
            setForm({ ...form, oldPassword: form.oldPassword, newPassword: form.newPassword });
          } else {
            setAlertMessage("Password could not be changed.");
            setAlertType("error");
          }
        });
      }
    });
  };

  const handleDeleteAccount = () => {
    setIsDelete(!isDelete);
  };

  const handleSendCode = () => {
    if (form.email === "") {
      setAlertMessage("Please enter the email");
      setAlertType("error");
      setShowAlert(true);
      return;
    } else if (form.email !== form.reEnterEmail) {
      setAlertMessage("Emails do not match");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    setIsEmailVerify(!isEmailVerify);
  };

  const closeAlert = () => {
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
  };

  return (
    <div>
      <div className="setting-heading-main-container">
        <div className="setting-heading-container">
          <h2 className="setting-heading">Settings</h2>
          <p className="setting-para">
            Make change to your account and allow access control for sub-users for subscriptions here!
          </p>
        </div>
      </div>

      <CardLayout
        title={"Change Email"}
        isOpen={activeCard}
        handler={() => setActiveCard(!activeCard)}
        handleSubmit={handleEmailCardSubmit}
      >
        <EmailForm
          form={form}
          handleChange={handleChange}
          handleSendCode={handleSendCode}
          email={userData.user_email}
        />
      </CardLayout>

      <CardLayout
        title={"Change Company Name"}
        isOpen={activeCard2}
        handler={() => setActiveCard2(!activeCard2)}
        handleSubmit={handleCompanyCardSubmit}
      >
        <CompanyForm
          form={form}
          handleChange={handleChange}
          company={userData.companyName}
        />
      </CardLayout>

      <CardLayout
        title={"Change Password"}
        isOpen={activeCard3}
        handler={() => setActiveCard3(!activeCard3)}
        handleSubmit={handlePasswordCardSubmit}
      >
        <PasswordForm form={form} handleChange={handleChange} />
      </CardLayout>

      <UserCard
        isOpen={activeCard4}
        handler={() => setActiveCard4(!activeCard4)}
        handleAddBtn={() => setIsAdd(!isAdd)}
        users={fakeUsers}
        setUsers={setFakeUsers}
        setIsOpen={setActiveCard4}
      />

      <div className="settings-delete-btn-cont">
        <button className="settings-delete-btn" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </div>

      {/* Delete Model */}
      <ModalLayout isOpen={isDelete} onClose={() => setIsDelete(!isDelete)}>
        <DeleteForm onClose={() => setIsDelete(!isDelete)} />
      </ModalLayout>

      {/* Add Edit Model */}
      <ModalLayout isOpen={isAdd} onClose={() => setIsAdd(!isAdd)}>
        <AddForm
          onClose={() => setIsAdd(!isAdd)}
          users={fakeUsers}
          setUsers={setFakeUsers}
        />
      </ModalLayout>

      {/* Send Code Model */}
      <ModalLayout isOpen={isEmailVerify} onClose={() => setIsEmailVerify(!isEmailVerify)}>
        <EmailVerifyForm
          onClose={() => setIsEmailVerify(!isEmailVerify)}
          setEmailVerified={setEmailVerified}
        />
      </ModalLayout>

      {/* Dynamic Alert Box */}
      {showAlert && (
        <AlertBox
          message={alertMessage}
          onClose={closeAlert}
          type={alertType} // Pass the alert type (error or success)
        />
      )}
    </div>
  );
};

export default Settings;
