import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/contactForm.css";
import InputSelector from "../../../components/Selector";
import TextArea from "../../../components/TextArea";
import { getSupportCategories, submitContactUs } from "../../../controller/ContactSupportController";

const Form = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [formData, setFormData] = useState({
    category: "",
    subCategory: "",
    description: "",
  });

  useEffect(() => {
    getSupportCategories().then((response) => {
      if (response?.status === "OK") {
        setCategories(response?.supportCategoriesResponseDtoList);
      }
    });
  }, []);

  useEffect(() => {
    if(formData?.category){
      const selectedCategory = categories?.find((item) => item?.id == formData?.category);
      setSelectedCategory(selectedCategory);      
    }
  }, [formData?.category]);


  const navigate = useNavigate();

  //   handle change in input fields
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // submit form click
  const handleSubmit = (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("user"));
    const payload = {
      message: formData.description,
      status: "TODO",
      user: {
        "user-id": user.userResponseList[0]["user_id"],
      },
      "support-categories-id": formData.category.toString(),
    };
    submitContactUs(payload).then((response) => {
      if (response?.status === "OK") {
        navigate("/ContactSupport");
      }
    });
  };

  // get categories options from categoriesCollection
  const getCategoriesOptions = () => {
    const categoriesOptions = categories.map((item) => ({
      value: item.id,
      label: item.display_name,
    }))

    return categoriesOptions || [];
  };

  // get sub categories options from categoriesCollection
  const getSubCategoriesOptions = () => {
    const subCategoryOptions = selectedCategory?.description?.split(",")?.map((item) => ({
      value: item,
      label: item,
    }));
    return subCategoryOptions || [];
  };

  return (
    <form class="form-container" onSubmit={handleSubmit}>
      <InputSelector
        options={getCategoriesOptions()}
        value={formData.category}
        onChange={handleChange}
        name="category"
      />
      <InputSelector
        options={getSubCategoriesOptions()}
        value={formData.subCategory}
        onChange={handleChange}
        name="subCategory"
      />
      <TextArea
        placeholder={"Please describe your issue."}
        value={formData.description}
        onChange={handleChange}
        name="description"
        rows={10}
      />
      <div>
        <button class="button">Submit</button>
      </div>
    </form>
  );
};

export default Form;
