import React, { useState } from "react";
import "../../styles/login.css";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ForgotPasswordModel from "./ForgotPasswordModel";
import { validateLogin } from "../../utils/Validations";
import { Formik } from "formik";
import * as API from "../../controller/UserController";
import AlertBox from "../../components/AlertBox"; // Import the AlertBox component


const Login = ({ setUser }) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState(""); // State to store alert message
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    API.verifyLogin(values.email, values.password).then((response) => {
      if (response.status === "OK") {
        API.getUserByPrimaryInfo(values.email).then(
          (response) => {
            if (response.status === "OK") {
              sessionStorage.setItem("user", JSON.stringify(response));
              setUser(response);
              navigate("/");
            }
          }
        );
      } else {
        // Set alert message and show the alert box
        setAlertMessage("Invalid Credientials! Please Try Again or Reset the Password");
        setShowAlert(true);
      }
    });
  };

  const handleForgotPasswordClose = () => {
    setIsForgotPassword(false);
  };

  // Function to close the alert box
  const closeAlertBox = () => {
    setShowAlert(false);
  };

  return (
    <div className="main-container">
      <div className="login-header-container">CipherTEA</div>
      <div className="login-container">
        <div className="extra-container">
          <div className="header-container">
            <span>WELCOME</span>
            <p>Log in to continue to CipherTEA</p>
          </div>
          <Formik
            initialValues={initialValues}
            validate={(values) => validateLogin(values)}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form">
                  <div className="input-container">
                    <InputField
                      type="text"
                      label="Email"
                      name="email"
                      value={values.email}
                      setValue={handleChange}
                      errorMessage={errors.email}
                    />
                    <InputField
                      type="password"
                      label="Password"
                      name="password"
                      value={values.password}
                      setValue={handleChange}
                      errorMessage={errors.password}
                    />
                  </div>
                  <div className="btn-links-contianer">
                    <span className="link forgot-link">
                      <span onClick={() => setIsForgotPassword(true)}>
                        Forgot Password?
                      </span>
                    </span>
                    <Button text="Log in"  />
                    <div className="not-account">
                      <span className="link" style={{ cursor: "default" }}>
                        Don't have an account?
                      </span>
                      <Link to="/signup" className="link" >
                        <span className="link-signup"> Sign up</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <div className="square-box">
          <span className="title"style={{ display: "block", textAlign: "center" }}>FACT CHECK:</span>
          <span className="message">There were more than 3,200 data breaches in 2023. Let’s work together to minimize this, going forward.</span>
        </div>
      </div>

      {/* Forgot password model */}
      <ForgotPasswordModel
        isOpen={isForgotPassword}
        onClose={handleForgotPasswordClose}
      />

      {/* AlertBox component */}
      {showAlert && (
        <AlertBox message={alertMessage} onClose={closeAlertBox} />
      )}
    </div>
  );
};

export default Login;