import React, { useEffect, useState } from "react";
import "../../../styles/contactForm.css";
import Form from "./Form";

const ContactForm = ({user}) => {
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user?.userResponseList[0].user_email) {
      setUserEmail(user.userResponseList[0].user_email);
    }
  }, []);

  return (
    <div className="main-container">
      
      <h1 className="heading">CipherTEA Support</h1>
      <div className="content-container">
        <div className="contact-header">
          <h1 className="heading2">Contact Us Now</h1>
          <div className="para">
            <p style={{ display: "block", textAlign: "center" }}>We've got you covered!</p>
          </div>
          <div className="para2">
              <p className="para2-email">
              <strong>Email:</strong> {user.userResponseList[0]?.user_email}
            </p>
          </div>
        </div>
        <Form userEmail={userEmail} />
      </div>
    </div>
  );
};

export default ContactForm;
